var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    register: true,
    loginFootball: _vm.getBackgroundClass === 'loginFootball',
    loginBaseball: _vm.getBackgroundClass === 'loginBaseball',
    loginBasketball: _vm.getBackgroundClass === 'loginBasketball',
    loginSoccer: _vm.getBackgroundClass === 'loginSoccer',
  }},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(this.gif),expression:"this.gif"}],staticClass:"animated-gif center",attrs:{"id":"mygif","src":require("../../../public/1495.gif")}})])
}
var staticRenderFns = []

export { render, staticRenderFns }