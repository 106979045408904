<template>
  <div
    :class="{
      register: true,
      loginFootball: getBackgroundClass === 'loginFootball',
      loginBaseball: getBackgroundClass === 'loginBaseball',
      loginBasketball: getBackgroundClass === 'loginBasketball',
      loginSoccer: getBackgroundClass === 'loginSoccer',
    }"
  >
    <img
      id="mygif"
      src="../../../public/1495.gif"
      class="animated-gif center"
      v-show="this.gif"
    />
    <!--    <registerComponent-->
    <!--      v-show="this.toggle && !this.gif"-->
    <!--      @submitted="registerUser"-->
    <!--      :regObj="this.register"-->
    <!--    ></registerComponent>-->
    <!--<DebitInfo v-show="!this.toggle && !this.gif" @submitted="registerUser" />-->
  </div>
</template>
<script>
// import registerComponent from "../components/registerComponent";
//import DebitInfo from "@/components/auth/DebitInfo.vue";
import swal from "sweetalert";
import axios from "axios";

export default {
  components: {
    // registerComponent,
    //DebitInfo,
  },
  data() {
    return {
      toggle: true,
      gif: false,
      register: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        address: "",
        phoneNumber: "",
        state: "",
        cardData: {},
      },
    };
  },
  methods: {
    toggleButton() {
      this.toggle = !this.toggle;
    },
    sendUser() {
      console.log("here");
    },
    async registerUser() {
      //this.register.cardData = cardInfo;

      this.gif = true;
      let url = process.env.VUE_APP_DB_URL + "user/register";

      axios
        .post(url, this.register, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          let token = response.data.token;
          localStorage.setItem("jwt", token);
          if (token) {
            localStorage.setItem("jwt", token);
            this.$router.push("/Dashboard");
            swal("Success", "Registration Was successful", "success");
          }
        })
        .catch(function (err) {
          let error = err.response;
          console.log(error);
          swal("Error", error.data.errorMessage, "error");
          this.$router.push("/register");
        });
    },
  },
  computed: {
    getBackgroundClass() {
      const classes = [
        "loginFootball",
        "loginBaseball",
        "loginBasketball",
        "loginSoccer",
      ];
      return classes[Math.floor(Math.random() * classes.length)];
    },
  },
};
</script>
<style scoped>
.center {
  position: absolute;
  left: calc(50% - 65px);
  display: block;
  top: calc(50% - 65px);
}
</style>
